import axios from 'axios';
import config from '../config';

async function httpRequest(url, method, params, retry = 0, e) {
    if (retry === 5)
        if (e.response)
            return { code: 'error', data: e.response.data };
        else
            return { code: 'error', data: 'Serveur distant non opérationnel' };
    try {
        const { data } = await axios({
            baseURL: config.apiUrl,
            url,
            method,
            headers: {
                'Authorization': localStorage.getItem('WebRequestPublicKey') || ''
            },
            data: params
        });
        return data;
    } catch (e) {
        return httpRequest(url, method, params, retry + 1, e)
    }
}

export default httpRequest;