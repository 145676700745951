import React from 'react';
import { BrowserRouter as Router, Route, Switch, useLocation, Link } from 'react-router-dom';

import { TransitionGroup, CSSTransition } from 'react-transition-group'

import MainPage from './Home/MainPage';
import JardinagePage from './Prestation/JardinagePage';

import DepannagePage from './Prestation/DepannagePage';

import RDV from './Devis/DemandeRDV';

function PageNotFound() {
    return (
        <div>
            <h1 id="NoPageFound">La page n'existe pas</h1>
            <Link className="btn btn-primary position-center fixed mt-5" to='/'>Retourner sur la page principale</Link>
        </div>
    )
}

function AnimationApp() {
    let location = useLocation();
    return (
        <TransitionGroup>
            <CSSTransition key={location.key} classNames="slide" timeout={400}>
                <Switch location={location}>
                    <Route exact path='/depannage/rdv' component={(props) => <RDV {...props} prestation={'depannage'} />} />
                    <Route exact path='/jardinage/rdv' component={(props) => <RDV {...props} prestation={'entretien du jardin'} />} />
                    <Route exact path='/depannage' component={DepannagePage} />
                    <Route exact path='/jardinage' component={JardinagePage} />
                    <Route exact path='/' component={MainPage} />
                    <Route path='*' component={PageNotFound} />
                </Switch>
            </CSSTransition>
        </TransitionGroup>
    );
}

export default function App() {
    return (
        <Router>
            <AnimationApp />
        </Router>
    )
}