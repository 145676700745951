import React from 'react';
import { Link } from 'react-router-dom';
import { Drawer } from 'rsuite';
import supportWebP from '../../utils/supportWebP';

import informatique from '../../assets/json/informatique.json';

let searchList = [];
informatique.prestation_description.map(({ liste, title }) => {
    return liste.map(({ description }) => {
        return searchList.push({ label: description.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, ""), value: description, title });
    })
})

class Test extends React.Component {
    constructor() {
        super();
        this.state = {
            show: false,
            position_item: {},
            prestation_description: { title: "", liste: [] },
            show_item: false
        }
        this.liste_items = [];
        this.searchElement = [];
        this.close = this.close.bind(this);
        this.open = this.open.bind(this);
        this.search = this.search.bind(this);
        this.removeItemBar = this.removeItemBar.bind(this);
        this.resize = this.resize.bind(this);
        this.supportWebP = supportWebP();
    }

    close() {
        this.setState({
            show: false
        });
    }

    open(e) {
        this.setState({ show_item: false })
        let name = e.target.name || e.target.title
        let temp = informatique.prestation_description.find(element => element.title === name);
        this.setState({
            show: true,
            prestation_description: temp
        })
    }

    search() {
        this.searchElement = document.getElementsByTagName("input")[0];
        this.liste_items = [];
        if (this.searchElement.value !== '')
            searchList.map(data => {
                if (data.label.includes(this.searchElement.value.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "")))
                    return this.liste_items.push({ value: data.value, title: data.title });
                else
                    return false;
            })
        else
            this.liste_items = [...searchList];
        this.setState({ position_item: { top: this.searchElement.offsetTop + this.searchElement.offsetHeight, left: this.searchElement.offsetLeft, width: this.searchElement.offsetWidth }, show_item: true });
    }

    removeItemBar(e) {
        if (e.target.className !== 'search-item' && e.target.type !== 'search')
            this.setState({ show_item: false })
    }

    resize() {
        if (this.state.show_item)
            this.setState({ position_item: { top: this.searchElement.offsetTop + this.searchElement.offsetHeight, left: this.searchElement.offsetLeft, width: this.searchElement.offsetWidth } });
    }

    render() {
        window.addEventListener('click', this.removeItemBar);
        window.addEventListener('resize', this.resize);
        return (
            <div className="Prestation Depannage">
                <nav>
                    <h4><Link to='/'>Accueil</Link></h4>
                    <input className="form-control" type="search" placeholder="Recherche" onChange={this.search} onClick={this.search} />
                    <h2 className="">Quels types de problèmes avez-vous ?</h2>
                </nav>

                <center>
                    <div className="container-card align-content-end flex-wrap">
                        {informatique.prestation.map(({ title, description, image }, index) => (
                            <div className={`material-card${(index === 5 || index === 11) ? " mr-00" : ""}${(index === 0 || index === 6) ? " ml-00" : ""}`} key={title}>
                                <div className="material-card-header">
                                    <img src={require(`../../assets/images/${this.supportWebP}/${image}.${this.supportWebP}`)} alt={image} />
                                    <h4 className="text-center">{title}</h4>
                                </div>
                                <div className="material-card-body">
                                    <p>{description}</p>
                                    <button type="button" className="btn btn-warning mt-3" onClick={this.open} name={title} >Découvrez le prix</button>
                                </div>
                            </div>
                        ))}
                    </div>
                </center>

                <h4 className="prestation-link-bottom my-2">Faire une demande de <Link to='/depannage/rdv' className="devis_link">renseignements ? </Link></h4>

                {this.state.show_item && (
                    <div className="search-item-bar" style={this.state.position_item}>
                        {this.liste_items.map(data => (
                            <p className="search-item" onClick={this.open} key={data.value} title={data.title}>{data.value}</p>
                        ))}
                        {this.liste_items.length === 0 && (
                            <p className="search-item">Aucune prestation de trouvée</p>
                        )}
                    </div>
                )}

                <Drawer backdrop={true} show={this.state.show} onHide={this.close}>
                    <Drawer.Header>
                        <Drawer.Title>{this.state.prestation_description.title}</Drawer.Title>
                    </Drawer.Header>
                    <Drawer.Body>
                        <ul className="arrow-list" >
                            {this.state.prestation_description.liste.map(({ description, price, deduction }, index) => (
                                <li key={index} className="mb-2">
                                    <p>{description}</p>
                                    {informatique.showPrice && (
                                        <div>
                                            <p><b>Prix : </b>A partir de {price}</p>
                                            {informatique.showDeduction && (
                                                <p><b>Deduction </b>de {deduction}</p>
                                            )}
                                        </div>
                                    )}
                                </li>
                            ))}
                        </ul>
                    </Drawer.Body>
                    <Drawer.Footer>
                        <center>
                            <Link to='/depannage/rdv' className="btn btn-success mb-3 mx-3" onClick={this.close}>Faire une demande de renseignements.</Link>
                            <button type="button" className="btn btn-danger mb-3 mx-3" onClick={this.close}>Quitter</button>
                        </center>
                    </Drawer.Footer>
                </Drawer>

            </div >
        )
    }
}

export default Test;