import React from 'react';
import { Link } from 'react-router-dom';
import supportWebP from '../../utils/supportWebP';

import jardinage from '../../assets/json/jardinage.json';

let supportWebPLink = supportWebP();

class JardinagePage extends React.Component {
    render() {
        return (
            <div className="Prestation Jardinage">

                <nav className="mb-4">
                    <h4><Link to='/'>Accueil</Link></h4>
                    <h2>Quels types de services avez-vous besoin ?</h2>
                </nav>

                <h4 className="prestation-link mb-5">Faire une demande de <Link to='/jardinage/rdv' className="devis_link">renseignements ? </Link></h4>

                <center>
                    <div className="container-card align-content-end flex-wrap">
                        {jardinage.prestation.map(({ title, description, image, price, deduction }, index) => (
                            <div className={`material-card${(index === 5 || index === 11) ? " mr-00" : ""}${(index === 0 || index === 6) ? " ml-00" : ""}`} key={title}>
                                <div className="material-card-header">
                                    <img src={require(`../../assets/images/${supportWebPLink}/${image}.${supportWebPLink}`)} alt={image} />
                                    <h4 className="text-center">{title}</h4>
                                </div>
                                <div className="material-card-body">
                                    <p>{description}</p>
                                    {jardinage.showPrice && (
                                        <div>
                                            <div className="rs-divider rs-divider-horizontal w-100" />
                                            <p className="mb-3">A partir de {price}</p>
                                            {jardinage.showDeduction && (
                                                <p className="mb-3 font-italic font-weight-light">Avec deduction de {deduction}</p>
                                            )}
                                        </div>
                                    )}
                                </div>
                            </div>
                        ))}
                    </div>
                </center>
            </div >
        )
    }
}

export default JardinagePage;