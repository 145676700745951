import React from 'react';
import { Link } from 'react-router-dom';
import { Notification, TagPicker } from 'rsuite';

import httpRequest from '../../utils/httpRequest';

import { ReactComponent as AlertIcon } from '../../assets/icons/alert-circle.svg';

import page_jardinage from '../../assets/json/jardinage.json';
import page_informatique from '../../assets/json/informatique.json';

class DemandeRDV extends React.Component {
    constructor() {
        super();
        this.state = {
            verification: false,
            show: false,
            prestation_error: '',
            city_error: '',
            requestStatus: false,
            mailExist: false
        }
        this._insertValue = this._insertValue.bind(this);
        this._sendVerification = this._sendVerification.bind(this);
        this._removeVerification = this._removeVerification.bind(this);
        this._sendMail = this._sendMail.bind(this);
        this._redirection = this._redirection.bind(this);
        this._search = this._search.bind(this);
        this._removeItemBar = this._removeItemBar.bind(this);
        this._getAllZip_Code = this._getAllZip_Code.bind(this);
        this._show_zip = false;
        this.zip_code = [];
        this.city_code = [];
        this.liste_items = [];
        this.type = null;
    }

    componentDidMount() {
        this._getAllZip_Code();
    }

    async _getAllZip_Code(){
        let res = (await httpRequest('/getzipcode', 'get'));
        if (res.code !== 'error')
            this.zip_code = res
    }

    _insertValue(e, name) {
        if (name) {
            this[name] = e.target.innerHTML;
            document.getElementById(name).value = e.target.innerHTML;
        }
        else
            this[e.target.name] = e.target.value;
    }

    async _sendVerification(e) {
        e.preventDefault();

        if (!this.state.mailExist) {
            let res = (await httpRequest(`/getcity/${this.zip}`, 'get'));
            if (res.code !== 'error')
                this.city_code = res
            let exist = false;
            this.city_code.map(data => {
                if (data.value.toLowerCase() === this.city.toLowerCase())
                    return exist = true
                return false
            })
            if (!exist) {
                this.setState({ city_error: 'border_error' })
                return this._createNotification('warning', 'Veuillez renseigner une ville exitante');
            }
        }

        if (this.type === null || this.type.length === 0) {
            this.setState({ prestation_error: 'border_error' })
            return this._createNotification('warning', 'Veuillez renseigner une prestation');
        }
        this.setState({ verification: true });
    }

    _removeVerification() {
        this.setState({ verification: false });
    }

    _createNotification(code, message) {
        if (code === 'success') {
            Notification[code]({
                title: 'JardInfo',
                duration: 0,
                description: <p style={{ whiteSpace: 'pre-line' }}>{message}</p>
            });
            setTimeout(this._redirection, 2000);
        }
        else
            Notification[code]({
                title: 'JardInfo',
                duration: 10000,
                description: <p style={{ whiteSpace: 'pre-line' }}>{message}</p>
            });
    }

    _redirection() {
        this.props.history.push('/');
    }

    async _sendMail() {
        this.setState({ verification: false, requestStatus: true });
        const tmp = await httpRequest('/appointment/create', 'post', { email: this.email, name: this.name, last_name: this.last_name, cell_number: this.cell_number, adress: this.adress, zip_code: this.zip, city: this.city, type: this.type, prestation: this.props.prestation, message: this.message });
        if (tmp.open)
            this.setState({ requestStatus: false, mailExist: false });
        else
            this.setState({ requestStatus: false });
        this._createNotification(tmp.code, tmp.message);
    }

    async _search(e) {
        const eventTarget = e.target;
        if (eventTarget.name === 'city' && this.zip) {
            this.setState({ city_error: '' })
            let res = (await httpRequest(`/getcity/${this.zip}`, 'get'));
            if (res.code !== 'error')
                this.city_code = res
        }
        this.liste_items = { name: eventTarget.name, array: [] };
        let i = 0;
        let temp = [...this[eventTarget.name + '_code']];
        this[eventTarget.name] = eventTarget.value;

        if (eventTarget.value !== '')
            while (i !== temp.length && this.liste_items.array.length !== 10) {
                if (temp[i].value.toString().toLowerCase().includes(eventTarget.value.toLowerCase()))
                    this.liste_items.array.push(temp[i]);
                i++;
            }
        else if (eventTarget.name === 'city')
            this.liste_items.array = this.city_code;
        this.setState({ position_item: { top: eventTarget.offsetTop + eventTarget.offsetHeight, left: eventTarget.offsetLeft, width: eventTarget.offsetWidth }, show: true });
    }

    _removeItemBar(e) {
        if (this.state.show)
            if (e.target.name !== 'zip' && e.target.name !== 'city') {
                this.setState({ show: false })
            }
    }

    render() {
        let data = [];
        if (this.props.prestation !== 'depannage')
            page_jardinage.prestation.map((value, index) => {
                return data[index] = { value: value.title, label: value.title };
            })
        else
            page_informatique.prestation_description.map(({ title, liste }) => {
                return liste.map(({ description }) => {
                    return data.push({ label: description, value: description, type: title });
                })
            });

        data[data.length] = { label: 'Autre...', value: 'Autre...', type: 'Autre' }
        window.addEventListener('click', this._removeItemBar);
        return (
            <div className="py-4">
                <Link to='/' className="mx-4">Accueil</Link>
                <div className="container mt-3">
                    <h3>Demande de renseignements ?</h3>
                    <form className="mx-3 mt-3 d-flex flex-column" onSubmit={this._sendVerification}>
                        <div className="form-group">
                            <label>Votre adresse e-mail</label>
                            <input type="email" className="form-control" placeholder="nom@exemple.fr" name="email" onChange={this._insertValue} required />
                            <small className="form-text text-muted">Nous ne partagerons jamais votre e-mail.</small>
                            <label className="m-0">Si vous avez déjà envoyé un rendez-vous, vous pouvez cliquer <p className="emailExist" onClick={() => this.setState({ mailExist: !this.state.mailExist })}>ici</p></label>
                        </div>

                        {!this.state.mailExist && (
                            <div>
                                <div className="form-panel">
                                    <span>
                                        <div>
                                            <label>Nom</label>
                                            <input type="text" className="form-control" name="name" onChange={this._insertValue} required />
                                        </div>

                                        <div>
                                            <label>Prénom</label>
                                            <input type="text" className="form-control" name="last_name" onChange={this._insertValue} required />
                                        </div>
                                    </span>

                                    <div>
                                        <label>Numéro de téléphone</label>
                                        <input type="tel" className="form-control" pattern="^0\d(\s|-)?(\d{2}(\s|-)?){4}$" name="cell_number" onChange={this._insertValue} required />
                                    </div>
                                </div>

                                <div className="form-panel">
                                    <div className="adress">
                                        <label>Adresse</label>
                                        <input type="text" className="form-control" name="adress" onChange={this._insertValue} required />
                                    </div>

                                    <span>
                                        <div>
                                            <label>Code postal</label>
                                            <input type="text" className="form-control" name="zip" id="zip" pattern="^(\d{5}(\s|-)?)$" onChange={this._search} onClick={this._search} required />
                                        </div>

                                        <div>
                                            <label>Ville</label>
                                            <input type="text" className={"form-control " + this.state.city_error} name="city" id="city" onChange={this._search} onClick={this._search} required />
                                        </div>
                                    </span>
                                </div>
                            </div>
                        )}

                        <TagPicker
                            className={`my-3 ${this.state.prestation_error}`}
                            data={data}
                            placeholder="Sélectionner votre prestation"
                            groupBy={this.props.prestation === 'depannage' && ("type")}
                            onChange={(sel) => { this.type = sel; this.setState({ prestation_error: '' }) }}
                        />

                        <div className="form-group">
                            <label>Renseignements</label>
                            <textarea className="form-control" rows="5" name="message" onChange={this._insertValue} required />
                        </div>

                        <input type="submit" className="btn btn-primary" value="Envoyer" />
                    </form>

                    {this.state.show && this.liste_items.array.length !== 0 && (
                        <div className="search-item-bar rdv" style={this.state.position_item}>
                            {this.liste_items.array.map(({ value }) => (
                                <p className="search-item" onClick={(e) => this._insertValue(e, this.liste_items.name)} key={value}>{value}</p>
                            ))}
                        </div>
                    )}

                    {this.state.requestStatus && (
                        <div className="position-fixed position-center">
                            <div className="spinner-border" role="status" />
                        </div>
                    )}

                    {this.state.verification && (
                        <div className="toast position-center verification" role="alert">
                            <div className="toast-header">
                                <AlertIcon className="rounded mr-2" />
                                <strong className="mr-auto">Demande de renseignements ou de rendez-vous ?</strong>
                                <button type="button" className="ml-2 mb-1 close" onClick={this._removeVerification}>
                                    <span>&times;</span>
                                </button>
                            </div>
                            <div className="toast-body d-flex flex-column" style={{ backgroundColor: "#fff" }}>
                                <p className="px-3">Êtes-vous sur d'envoyer cette demande ?</p>
                                <ul className="text-left mt-3">
                                    <li>E-mail : {this.email}</li>
                                    <li>Nom : {this.name}</li>
                                    <li>Prénom : {this.last_name}</li>
                                    <li>Numéro de téléphone : {this.cell_number}</li>
                                    <li>Adresse : {this.adress}</li>
                                    <li>Code postal : {this.zip}</li>
                                    <li>Ville : {this.city}</li>
                                    <li style={{ whiteSpace: "pre-line" }}>
                                        Renseignements :
                                    <p id="display_renseignement">{this.message}</p>
                                    </li>
                                    <li>
                                        Prestation(s) :
                                    <div id="display_prestation">
                                            {this.type.map(data =>
                                                data !== false && data !== null && (
                                                    <p key={data}> - {data}</p>
                                                )
                                            )}
                                        </div>
                                    </li>
                                </ul>
                                <span className="d-flex justify-content-around mt-3">
                                    <button type="button" className="btn btn-danger" onClick={this._removeVerification}>Annuler</button>
                                    <button type="button" className="btn btn-success" onClick={this._sendMail}>Valider</button>
                                </span>
                            </div>
                        </div>
                    )}
                </div>
            </div>
        )
    }
}

export default DemandeRDV;