import React from 'react';
import { Link } from 'react-router-dom';
import supportWebP from '../../utils/supportWebP';

import httpRequest from '../../utils/httpRequest';

import { ReactComponent as MouseIcon } from '../../assets/icons/mouse-pointer.svg';

let supportWebPLink = supportWebP();

class MainPage extends React.Component {
    async componentDidMount() {
        const { token } = await httpRequest('/web', 'post');
        if (token)
            localStorage.setItem('WebRequestPublicKey', token);
    }
    render() {
        return (
            <div className="MainPage">
                <img src={require(`../../assets/images/${supportWebPLink}/background.${supportWebPLink}`)} alt="Background" id="background" className="fixed" />
                <p className='address-information'>23a rue de Huttenheim 67230 Benfeld</p>
                <p className='phone-information'>Tél : 06.19.12.33.01</p>
                <Link to='/jardinage'><img src={require(`../../assets/images/${supportWebPLink}/outils.${supportWebPLink}`)} alt="Outils icons" id="outils" className="fixed" /></Link>
                <Link to='/depannage'><img src={require(`../../assets/images/${supportWebPLink}/computer.${supportWebPLink}`)} alt="Computer icons" id="computer" className="fixed" /></Link>
                <Link to='/jardinage' className="fixed" id="jardinage">Entretien du jardin<MouseIcon className='cursor'/></Link>
                <Link to='/depannage' className="fixed" id="informatique"><MouseIcon className='cursor rotate'/>Dépannage informatique</Link>
                <p id="title">JardInfo</p>
            </div>
        )
    }
}

export default MainPage;